<template>
    <div>
        <v-form id="form_rfid_user" ref="form" v-model="valid" lazy-validation>
            <v-card id="form_card">
                <v-card-title>
                    <v-icon left>mdi-car-shift-pattern</v-icon>
                        <span class="subtitle-1 font-weight-regular application--card--title">Podatki o zasedenosti parkirišča</span>
                        <v-spacer></v-spacer>
                    <v-btn icon @click="toggleCardContent('parking_occupancy')">
                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider v-show="card1"></v-divider>

                <!-- osebni podatki -->
                <v-card-text v-show="card1">
                    <v-container grid-list-xl text-x-center align-center class="container--filters">
                        <v-layout row justify-center v-bind="columnBinding">
                            <v-flex xs12 md4 align-content-center>
                                <v-subheader class="pt-7 pl-0"><strong>{{ itemData.naziv }}</strong></v-subheader>
                            </v-flex>
                            <v-flex xs12 md8 align-content-center>
                                <v-text-field
                                    v-model="parking"
                                    label="Oznaka parkirišča"
                                    append-icon="mdi-bookmark"
                                    readonly
                                    filled
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row justify-center v-bind="columnBinding">
                            <v-flex xs12 md4 align-content-center>
                                <v-subheader class="pt-7 pl-0"><strong>Vseh parkirnih mest</strong></v-subheader>
                            </v-flex>
                            <v-flex xs12 md8 align-content-center>
                                <v-text-field
                                    v-model="parking_slots"
                                    label="Število vseh parkirnih mest"
                                    append-icon="mdi-parking"
                                    readonly
                                    filled
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row justify-center v-bind="columnBinding">
                            <v-flex xs12 md4 align-content-center>
                                <v-subheader class="pt-7 pl-0"><strong>Zasedenih mest</strong></v-subheader>
                            </v-flex>
                            <v-flex xs12 md8 align-content-center>
                                <v-text-field
                                    v-model="parking_vehicles"
                                    label="Število vseh zasedenih mest"
                                    append-icon="mdi-car-3-plus"
                                    readonly
                                    filled
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row justify-center v-bind="columnBinding">
                            <v-flex xs12 md4 align-content-center>
                                <v-subheader class="pt-7 pl-0"><strong>Zasedenost parkirišča</strong></v-subheader>
                            </v-flex>
                            <v-flex xs12 md8 align-content-center>
                                <v-text-field
                                    v-model="parking_occupancy"
                                    label="Zasedenost parkirišča"
                                    append-icon="mdi-percent-outline"
                                    readonly
                                    filled
                                ></v-text-field>
                            </v-flex>
                        </v-layout>

                        <!-- vpisovanje podatkov -->
                        <v-layout row justify-center v-bind="columnBinding">
                            <v-flex xs12 md4 align-content-center>
                                <v-subheader class="pt-7 pl-0"><strong>Vpišite trenutno število vozil na parkirišču</strong></v-subheader>
                            </v-flex>
                            <v-flex xs12 md8 align-content-center>
                                <v-text-field
                                    v-model="current_number_of_vehicles"
                                    label="Trenutno število vozil na parkirišču"
                                    append-icon="mdi-pencil"
                                    clearable
                                    type="number"
                                    :disabled="disableCurrentNumberOfVehicles"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row justify-center v-bind="columnBinding">
                            <v-flex xs12 md4 align-content-center>
                                <v-subheader class="pt-7 pl-0"><strong>Vpišite število prostih mest</strong></v-subheader>
                            </v-flex>
                            <v-flex xs12 md8 align-content-center>
                                <v-text-field
                                    v-model="empty_slots"
                                    label="Število prostih mest"
                                    append-icon="mdi-pencil"
                                    clearable
                                    type="number"
                                    :disabled="disableEmptySLots"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>

                <v-divider v-show="card1"></v-divider>

                <v-card-actions v-show="card1">
                    <v-btn @click="submitForm()" color="success" text :disabled="loading || (!disableCurrentNumberOfVehicles && !disableEmptySLots)"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                    <v-btn @click="clearForm()" color="error" text><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                </v-card-actions>

            </v-card>
        </v-form>
    </div>
</template>

<script>


export default {

    props: ['avtId', 'itemData'],
    name: 'parking-lot-occupancy',
    
    data: () => ({
        valid: true,
        form: true,
        card1: true,
        loading: false,
        parking: "",
        parking_slots: null,
        parking_vehicles: null,
        parking_occupancy: null,
        current_number_of_vehicles: null,
        empty_slots: null,
        refresh_interval: null
    }),

    watch: {

    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        disableCurrentNumberOfVehicles() {
            if(this.empty_slots != null && this.empty_slots !== undefined && this.empty_slots != '') {
                return true
            }

            return false
        },

        disableEmptySLots() {
            if(this.current_number_of_vehicles != null && this.current_number_of_vehicles !== undefined && this.current_number_of_vehicles != '') {
                return true
            }

            return false
        }
    },

    methods: {
        toggleCardContent(card) {
            if(card === 'parking_occupancy') {
                this.card1 = !this.card1
            }
        },

        submitForm() {
            var vm = this
            this.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('PARKING_OCCUPANCY_UPDATE', {
                stevilo_vozil: vm.current_number_of_vehicles,
                prostih_mest: vm.empty_slots,
                avt_id: this.avtId
            })
            .then(response => {
                //window.console.log(response.data)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Zasedenost parkirišča je bila uspešno posodobljena.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)

                vm.current_number_of_vehicles = null,
                vm.empty_slots = null

                setTimeout(() => {
                    vm.fetchParkingData()
                }, 1000)
            })
            .catch(error => {
                window.console.log("ERROR submitForm")
                window.console.log(error)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Pri posodobitvi zasedenosti parkirišča je prišlo do napake. Prosimo poskusite ponovno.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
            })
            .finally(() => {
                vm.$store.commit('SET_PROGRESS', false)
            })
        },

        clearForm() {
            this.current_number_of_vehicles = null;
            this.empty_slots = null
        },

        calculateOccupancy(parkirnaMesta, zasedenaMesta) {

            //window.console.log(parkirnaMesta)
            //window.console.log(zasedenaMesta)
            if(parkirnaMesta != 0 && parkirnaMesta != null)
            {
                //return Math.ceil((zasedenaMesta * 100) / parkirnaMesta)
                return parseInt((zasedenaMesta * 100) / parkirnaMesta)
            }

            return false
        },

        fetchParkingData(){
            var vm = this
            this.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('PARKING_OCCUPANCY_ITEM_DATA', this.avtId)
            .then(response => {
                //window.console.log(response.data)
                vm.parking = response.data.data.naziv
                vm.parking_slots = response.data.data.zasedenost.parkirna_mesta
                vm.parking_vehicles = response.data.data.zasedenost.zasedena_mesta
                vm.parking_occupancy = response.data.data.zasedenost.zasedenost

                /*
                let occupancy = this.calculateOccupancy(vm.parking_slots, vm.parking_vehicles);
                if(occupancy != false) {
                    vm.parking_occupancy = occupancy
                } else {
                    vm.parking_occupancy = response.data.data.zasedenost.zasedenost
                }
                */
            })
            .catch(error => {
                window.console.error("ERROR fetchParkingData")
                window.console.log(error)
            })
            .finally(() => {
                vm.$store.commit('SET_PROGRESS', false)
            })
            
        },
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 

        //window.console.log(this.avtId)
        //window.console.log(this.itemData)

        //this.fetchParkingData()
    },

    mounted() {
        var vm = this

        if(this.itemData !== null) {
            this.parking = this.itemData.naziv
            this.parking_slots = this.itemData.zasedenost.parkirna_mesta
            this.parking_vehicles = this.itemData.zasedenost.zasedena_mesta
            this.parking_occupancy = this.itemData.zasedenost.zasedenost
        }
    },

    destroyed() {
        var vm = this
        clearInterval(vm.refresh_interval)
        this.$store.commit('SET_PROGRESS', false)
        this.$store.commit('snackbarDisplay', false)
    }
}

</script>

<style scoped>

</style>